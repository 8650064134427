// src/components/Header.js
import React, { useEffect, useState } from "react";
import { Link as ScrollLink, Events, scroller } from "react-scroll";
import classNames from "classnames";

export default function Header() {
  const [isSticky, setIsSticky] = useState(false);
  const [pastHeader, setPastHeader] = useState(false);
  const [activeSection, setActiveSection] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY; // Current scroll position
      const nav = document.querySelector("nav");
      const navTop = nav.offsetTop; // Navigation's original position
      const headerHeight = document.querySelector("header > div").offsetHeight; // Header image height

      // Set sticky state when scrolling past the nav's original position
      setIsSticky(scrollTop > navTop);

      // Stop sticky behavior when scrolling back above the header
      setPastHeader(scrollTop > headerHeight);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Track active section
  useEffect(() => {
    Events.scrollEvent.register("begin", (to) => setActiveSection(to));
    Events.scrollEvent.register("end", (to) => setActiveSection(to));

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  return (
    <header>
      {/* Main Header Image Section */}
      <div className="relative text-center font-tattoo">
        <img
          src="images/benseoptical--hero.jpg"
          alt="Bense Optical"
          className="w-full h-auto max-h-[900px] object-cover"
        />
      </div>

      {/* Navigation Bar */}
      <nav
        className={classNames(
          "bg-secondary text-white w-full p-4 md:p-6 shadow-lg z-50 transition-all duration-500 ease-out",
          {
            "fixed top-0 transform translate-y-0 opacity-100":
              isSticky && pastHeader, // Sticky
            "relative transform opacity-90": !pastHeader, // Non-sticky with slight transition
          }
        )}
      >
        <ul className="flex flex-wrap justify-center space-x-3 md:space-x-8 lg:space-x-16 font-tattoo text-xs md:text-base lg:text-lg uppercase font-extrabold">
          {[
            { name: "About", id: "about" },
            { name: "Eye Wear", id: "eyewear" },
            { name: "Supplements", id: "supplements" },
            { name: "Contact", id: "locations" },
          ].map((item) => (
            <li key={item.id}>
              <ScrollLink
                to={item.id}
                smooth={true}
                duration={500}
                offset={-120} // Increased to prevent nav overlap
                spy={true}
                onSetActive={() => setActiveSection(item.id)} // Manually track active section
                className={classNames(
                  "cursor-pointer transition duration-200",
                  activeSection === item.id
                    ? "text-primary font-bold"
                    : "text-white hover:text-primary"
                )}
              >
                {item.name}
              </ScrollLink>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
}
