import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";

const businessGroups = [
  {
    logo: "images/benseoptical--logo--BMCC.svg",
    link: "https://bense.ca/",
    description: "ADVANCED CATARACT SURGERY WITH EXPERT CARE.",
  },
  {
    logo: "images/benseoptical--logo--SurgiSpa.svg",
    link: "https://bensesurgispa.com/",
    description: "LUXURY AESTHETIC TREATMENTS AND SURGICAL PROCEDURES.",
  },
  {
    logo: "images/benseoptical--logo--BenseBody.svg",
    link: "https://bensebody.ca/",
    description: "BODY CONTOURING AND WELLNESS SOLUTIONS.",
  },
];

export default function Group() {
  return (
    <section className="bg-secondary text-black pt-8 pb-8">
      <div className="container mx-auto">
        <h2 className="text-3xl font-semibold text-white mb-6 text-center uppercase">The Bense Group</h2>

        {/* Business Group Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 p-8 md:p-0">
          {businessGroups.map((group, index) => (
            <a
              key={index}
              href={group.link}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center text-center transition-transform duration-300 hover:scale-105"
            >
              <img
                src={group.logo}
                alt="Business Group Logo"
                className="w-full max-h-[120px] md:max-h-[80px] h-auto mb-4"
              />
              <p className="text-sm md:text-base font-medium uppercase">{group.description}</p>
            </a>
          ))}
        </div>
      </div>
      </section>

  );
}
