import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Header from './components/Header';
import EyeCare from './components/EyeCare';
import EyeWear from './components/EyeWear';
import Supplements from './components/Supplements';
import About from './components/About';
import Locations from './components/Locations';
import Group from './components/Group';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <Router>
      <div className="bg-white text-white font-sans min-h-screen border-b border-gray-800">
        <Routes>
          {/* Main page route */}
          <Route 
            path="/" 
            element={
              <>
                <Header />
                <div className="border-t border-gray-800">
                  <About />
                </div>
                <div className="border-t border-gray-800">
                  <EyeWear />
                </div>
                <div className="border-t border-gray-800">
                  <Supplements />
                </div>
                <div className="border-t border-gray-800">
                  <Locations />
                </div>
                <div className="border-t border-gray-800">
                  <Contact />
                </div>
                <div className="border-t border-gray-800">
                  <Group />
                </div>
                <div className="border-t border-gray-800">
                  <Footer />
                </div>
              </>
            } 
          />
          
          {/* Catch-all route forwards to home */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
