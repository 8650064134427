import React from 'react';

const eyewearOptions = [
  {
    title: "Contact Lenses",
    image: "images/benseoptical--eyewear--contacts.jpg",
    description:
      "Clear, comfortable vision without glasses. We offer a wide range of contact lenses, including free trials to find the perfect fit for you.",
  },
  {
    title: "Prescription Glasses",
    image: "images/benseoptical--eyewear--glasses.jpg",
    description:
      "High-quality frames and lenses tailored to your style and vision needs. From budget-friendly options to top designer brands, we have it all.",
  },
  {
    title: "Sunglasses",
    image: "images/benseoptical--eyewear--sunglasses.jpg",
    description:
      "Protect your eyes with stylish, high-performance sunglasses offering full UV and blue-light protection. Ask us about the best options for you.",
  },
];

export default function EyeWear() {
  return (
    <section id="eyewear" className="bg-secondary relative text-center p-8">
      <div className="relative z-10 lg:px-24 text-white">
        <h1 className="text-5xl md:text-6xl font-bold text-primary mb-6 uppercase">
          Eye Wear
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {eyewearOptions.map((option, index) => (
            <div key={index} className="bg-white p-6 rounded-lg shadow-md text-black">
              <img
                src={option.image}
                alt={option.title}
                className="w-full h-48 object-cover rounded-lg mb-4"
              />
              <h2 className="text-2xl font-semibold text-primary mb-2">
                {option.title}
              </h2>
              <p className="text-base md:text-lg">{option.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
