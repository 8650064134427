import React from 'react';
import { Link } from 'react-scroll';

export default function Supplements() {
  return (
    <section id="supplements" className="bg-white relative text-center p-8">
      <div className="relative z-10 lg:px-24 text-black">
        <h1 className="text-5xl md:text-6xl font-bold text-primary mb-6 uppercase">
          Vision Supplements
        </h1>
        <div className="flex flex-col md:flex-row items-center md:items-start justify-between">
          
          {/* Text Content */}
          <div className="md:w-1/2 text-left">
            <p className="text-base md:text-lg lg:text-xl mb-4 text-justify">
              Supplements play a crucial role in maintaining eye health, especially for individuals with eye conditions or a family history of eye disease. Our optometrists recommend personalized supplement plans based on your needs.
            </p>
            <p className="text-base md:text-lg lg:text-xl mb-4 text-justify">
              We offer a full range of trusted products, including Vitalux, MacuHealth, and Systane, along with Omega-3 supplements, artificial tears, and eyelid care products—all at competitive prices.
            </p>
            <p className="text-base md:text-lg lg:text-xl mb-4">
              Learn more at:
              <a href="https://www.macuhealth.com" className="text-primary"> MacuHealth</a>, 
              <a href="https://www.vitaluxvitamin.ca" className="text-primary"> Vitalux</a>, 
              <a href="https://www.systane.com" className="text-primary"> Systane</a>.
            </p>
            <p className="text-base md:text-lg lg:text-xl">
              <Link to="locations" smooth={true} duration={500} className="text-primary cursor-pointer">
                contact us
              </Link> to schedule a consultation.
            </p>
          </div>

          {/* Responsive Image */}
          <div className="md:w-1/2 flex justify-center md:justify-end mt-8 md:mt-0">
            <img
              src="images/benseoptical--supplements.png"
              alt="Eye Supplements"
              className="w-full max-w-xs md:max-w-sm lg:max-w-md h-auto rounded-lg shadow-md"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
