import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

export default function Contact() {
  const [form, setForm] = useState({ name: "", email: "", message: "" });
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const formRef = useRef();

  const validate = () => {
    let newErrors = {};
    if (!form.name) newErrors.name = "Name is required";
    if (!form.email) newErrors.email = "Email is required";
    if (!/\S+@\S+\.\S+/.test(form.email)) newErrors.email = "Invalid email format";
    if (!form.message) newErrors.message = "Message is required";
    if (!recaptchaToken) newErrors.recaptcha = "Please complete the reCAPTCHA";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) return;

    const emailData = {
      name: form.name,   // Match this with EmailJS template placeholder
      email: form.email, // Match this with EmailJS template placeholder
      message: form.message,
      "g-recaptcha-response": recaptchaToken // Required for EmailJS with reCAPTCHA enabled
    };

    emailjs
      .send("service_2l2368v", "template_dy4hb1p", emailData, "QEIhV8CNHvGUrywFq")
      .then(() => {
        setSuccess(true);
        setForm({ name: "", email: "", message: "" });
        setRecaptchaToken(null);
        setTimeout(() => setSuccess(false), 5000);
      })
      .catch((error) => console.error("EmailJS Error:", error));
  };

  return (
    <section className="bg-white text-black p-8">
      <div className="container mx-auto flex flex-col md:flex-row items-center gap-8 text-start">
        
        {/* Tagline Section */}
        <div className="w-full md:w-2/5 text-center md:text-left">
          <h2 className="text-3xl md:text-4xl font-semibold mb-4">
            Get in Touch with Us Today!
          </h2>
          <p className="text-lg text-black">
            Whether you have a question or need an appointment, we're here to help.
          </p>
        </div>

        {/* Contact Form Section */}
        <div className="w-full md:w-3/5 bg-secondary p-6 rounded-lg shadow-lg">
          {success && <p className="text-green-400 text-center mb-4">Message sent successfully!</p>}
          <form ref={formRef} onSubmit={handleSubmit} className="space-y-4">
            <div>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                value={form.name}
                onChange={handleChange}
                className="w-full p-3 bg-white text-black rounded-md outline-none focus:ring-2 focus:ring-primary"
              />
              {errors.name && <p className="text-red-400 text-sm mt-1">{errors.name}</p>}
            </div>
            <div>
              <input
                type="email"
                name="email"
                placeholder="Your Email"
                value={form.email}
                onChange={handleChange}
                className="w-full p-3 bg-white text-black rounded-md outline-none focus:ring-2 focus:ring-primary"
              />
              {errors.email && <p className="text-red-400 text-sm mt-1">{errors.email}</p>}
            </div>
            <div>
              <textarea
                name="message"
                placeholder="Your Message"
                rows="4"
                value={form.message}
                onChange={handleChange}
                className="w-full p-3 bg-white text-black rounded-md outline-none focus:ring-2 focus:ring-primary"
              ></textarea>
              {errors.message && <p className="text-red-400 text-sm mt-1">{errors.message}</p>}
            </div>

            {/* reCAPTCHA Section */}
            <div className="flex justify-center">
              <ReCAPTCHA
                sitekey="6LeDkcoqAAAAACAJEmobcKWxoKIF4o_E0CwBrLFn" // Replace with your real site key
                onChange={(token) => setRecaptchaToken(token)}
              />
            </div>
            {errors.recaptcha && <p className="text-red-400 text-sm text-center mt-1">{errors.recaptcha}</p>}

            <button
              type="submit"
              className="w-full bg-primary text-white py-3 rounded-md font-semibold hover:bg-opacity-80 transition"
            >
              Send Message
            </button>
          </form>
        </div>

      </div>
    </section>
  );
}
