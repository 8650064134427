import React from 'react';
import { Link } from 'react-scroll';

export default function About() {
  return (
    <section id="about" className="relative text-center p-8">
      <div className="relative z-10 lg:px-24 text-black">
        <h1 className="text-5xl md:text-6xl font-bold text-black uppercase mb-2">Bense Optical & Optometry</h1>
        <h2 className="text-2xl md:text-3xl font-bold text-primary uppercase mb-6">Newfoundland & Labrador’s Premier Optical & Optometry Suite</h2>

        <div className="flex flex-col md:flex-row items-center md:items-start justify-between">
          
          {/* Responsive Image */}
          <div className="md:w-1/2 flex justify-center md:justify-start mb-8 md:mb-0">
            <img
              src="images/benseoptical--about.jpg"
              alt="Bense Optical & Optometry"
              className="w-full max-w-xs md:max-w-sm lg:max-w-md h-auto rounded-lg shadow-md"
            />
          </div>
          
          <div className="md:w-1/2 text-left">
            <p className="text-base md:text-lg lg:text-xl font-normal mb-4 text-justify">
              Since 2006, Bense Optical & Optometry has been providing the Avalon with comprehensive eye exams and the latest in eyewear fashion. In 2016, we expanded to a second location in Paradise to serve you better. Both locations feature advanced technology, experienced opticians, and the largest selection of high-quality prescription glasses, contact lenses, and sunglasses—all at affordable prices.
            </p>
            <p className="text-base md:text-lg lg:text-xl font-normal mb-4 text-justify">
              We are committed to top-tier service, cutting-edge technology, and personalized eye care solutions. No referral is needed, and new patients are always welcome.
            </p>
            <p className="text-base md:text-lg lg:text-xl font-normal mb-4 text-justify">
              <Link to="locations" smooth={true} duration={500} className="text-primary cursor-pointer">
                Schedule an appointment today
              </Link> at either of our locations for comprehensive eye exams and expert optical services.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
