import React from "react";
import { FaPhone, FaFax, FaEnvelope, FaMapMarkerAlt, FaClock } from "react-icons/fa";

const locations = [
  {
    name: "St. John's Location",
    address: (
      <>
        Suite 108 Elizabeth Towers, <br />
        100 Elizabeth Ave, <br />
        St. John's, Newfoundland <br />
        A1B 1S1
      </>
    ),
    phone: "709.754.1250",
    fax: "709.754.3339",
    email: "info@benseoptical.com",
    hours: [
      { day: "Monday", time: "8am – 5pm" },
      { day: "Tuesday", time: "8am – 5pm" },
      { day: "Wednesday", time: "8am – 5pm" },
      { day: "Thursday", time: "8am – 5pm" },
      { day: "Friday", time: "8am – 4pm" },
    ],
  },
  {
    name: "Paradise Location",
    address: (
      <>
        69 Karwood Dr, Unit 2, <br />
        Paradise, Newfoundland <br />
        A1L 0L3 <br /> <br />
      </>
    ),
    phone: "709.702.2020",
    fax: "709.782.0560",
    email: "paradise@benseoptical.com",
    hours: [
      { day: "Monday", time: "8:30am – 4:30pm" },
      { day: "Tuesday", time: "8:30am – 4:30pm" },
      { day: "Wednesday", time: "8:30am – 4:30pm" },
      { day: "Thursday", time: "Closed" },
      { day: "Friday", time: "Closed" },
    ],
  },
];

export default function Locations() {
  return (
    <section id="locations" className="relative p-8 text-black text-lg">
      {/* Background Image */}
      <div className="absolute inset-0">
        <img
          src="images/benseoptical--bg.jpg"
          alt="Bense Optical"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      </div>

      <div className="relative z-10 container mx-auto p-2">
        <h1 className="text-5xl md:text-6xl font-bold text-primary mb-6 text-center uppercase">
          Contact Us
        </h1>

        {/* Location Info */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12">
          {locations.map((location, index) => (
            <div 
              key={index} 
              className="bg-white p-4 md:p-6 rounded-lg shadow-lg text-center md:text-left w-full md:max-w-3xl mx-auto"
            >
              <h2 className="text-primary text-2xl font-semibold mb-2">{location.name}</h2>
              <p className="flex items-start justify-center md:justify-start space-x-2 mb-2">
                <FaMapMarkerAlt className="text-black mt-1" />
                <span>{location.address}</span>
              </p>
              <p className="flex items-start justify-center md:justify-start space-x-2 mb-2">
                <FaPhone className="text-black mt-1" />
                <span>{location.phone}</span>
              </p>
              <p className="flex items-start justify-center md:justify-start space-x-2 mb-2">
                <FaFax className="text-black mt-1" />
                <span>{location.fax}</span>
              </p>
              <p className="flex items-start justify-center md:justify-start space-x-2">
                <FaEnvelope className="text-black mt-1" />
                <a href={`mailto:${location.email}`} className="text-primary hover:text-tertiary">
                  {location.email}
                </a>
              </p>
              <h3 className="text-primary text-xl font-semibold text-black mt-4 mb-2">
                Hours of Operation
              </h3>
              {location.hours.map((hour, i) => (
                <p key={i} className="flex items-start justify-center md:justify-start space-x-2">
                  <FaClock className="text-black mt-1" />
                  <span>{hour.day}: {hour.time}</span>
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
