import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";

export default function Footer() {
  return (
      <section className="bg-white  text-black">  
      <div className="bg-tertiary border-t border-gray-700 p-2 flex justify-center space-x-6">
        <a href="https://www.facebook.com/people/Bense-Optical-Optometry/100089499378688/" target="_blank" rel="noopener noreferrer" className="text-white hover:text-primary text-2xl">
          <FaFacebook />
        </a>
        <a href="http://instagram.com/BenseOptical" target="_blank" rel="noopener noreferrer" className="text-white hover:text-primary text-2xl">
          <FaInstagram />
        </a>
      </div>
    </section>
  );
}
